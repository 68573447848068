import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/header';

const NotFoundPage = () => (
  <Layout>
    <Header headerType="" />
    <SEO title="404: Not found" />
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>404</h1>
          <h1>Page not found</h1>
          <p>You just hit a route that doesn&#39;t exist...</p>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
